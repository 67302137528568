import { useArrayFindIndex, useArrayFind, useDateFormat } from "@vueuse/core";

export const useUtils = () => {
  const showCredit = (val) => {
    return val.toLocaleString("th-TH", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const flags = ref([
    "th.svg",
    "th.svg",
    "th.svg",
    "th.svg",
    "vn.svg",
    "my.svg",
    "la.svg",
    "th.svg",
    "jp.svg",
    "jp.svg",
    "cn.svg",
    "hk.svg",
    "tw.svg",
    "kr.svg",
    "sg.svg",
    "in.svg",
    "eg.svg",
    "ru.svg",
    "gb.svg",
    "de.svg",
    "us.svg",
    "vn.svg",
    "my.svg",
    "la.svg",
    "la.svg",
    "vn.svg",
    "vn.svg",
    "vn.svg",
    "vn.svg",
    "vn.svg",
    "la.svg",
    "yeekee_huay.webp",
    "yeekee_huay.webp",
    "yeekee_chj.webp",
    "yeekee_chj.webp",
    "yeekee_lto.webp",
    "yeekee_lto.webp",
    "yeekee_thl.webp",
    "yeekee_thl.webp",
    "yeekee_dna.webp",
    "yeekee_dna.webp",
  ]);

  const flagsSlug = ref([
    "lotto_thai",
    "lotto_thai_vip",
    "lotto_baac",
    "lotto_gsb",
    "lotto_vietnam",
    "lotto_malaysia",
    "lotto_lao",
    "set_thai",
    "set_japan",
    "set_nikkei",
    "set_china",
    "set_hangseng",
    "set_taiwan",
    "set_korea",
    "set_singapore",
    "set_india",
    "set_egypt",
    "set_russia",
    "set_uk",
    "set_germany",
    "set_us",
    "step_lotto_vietnam",
    "step_lotto_malaysia",
    "step_lotto_lao",
    "lotto_lao_star",
    "lotto_vietnam_vip",
    "lotto_vietnam",
    "lotto_hanoi",
    "lotto_hanoi_special",
    "lotto_hanoi_vip",
    "step_lao",
    "yeekee_huay_5",
    "yeekee_huay_15",
    "yeekee_chj_5",
    "yeekee_chj_15",
    "yeekee_lto_5",
    "yeekee_lto_15",
    "yeekee_thl_5",
    "yeekee_thl_15",
    "yeekee_dna_5",
    "yeekee_dna_15",
  ]);

  const showFlag = (value) => {
    let result = useArrayFindIndex(flagsSlug, (val) => val == value);
    if (result.value != -1) {
      return flags.value[result.value];
    } else {
      // return "thai-baht.webp";
      return "dlto.svg";
    }
  };

  const bgBank = (value) => {
    switch (value) {
      case "bay":
        return "bg-yellow-400";
        break;
      case "ktb":
        return "bg-sky-400";
        break;
      case "kbank":
        // code block
        return "bg-green-700";
        break;
      case "scb":
        // code block
        return "bg-violet-700";
        break;
      case "bbl":
        // code block
        return "bg-blue-800";
        break;
      case "ttb":
        // code block
        return "bg-amber-600";
        break;
      case "baac":
        // code block
        return "bg-emerald-900";
        break;
      case "gsb":
        // code block
        return "bg-fuchsia-400";
        break;
      default:
        // code block
        return "bg-yellow-500";
    }
  };

  const textColorBank = (value) => {
    switch (value) {
      case "bay":
        return "text-yellow-400";
        break;
      case "ktb":
        return "text-sky-400";
        break;
      case "kbank":
        // code block
        return "text-green-700";
        break;
      case "scb":
        // code block
        return "text-violet-700";
        break;
      case "bbl":
        // code block
        return "text-blue-800";
        break;
      case "ttb":
        // code block
        return "text-amber-600";
        break;
      case "baac":
        // code block
        return "text-emerald-900";
        break;
      case "gsb":
        // code block
        return "text-fuchsia-400";
        break;
      default:
        // code block
        return "text-green-500";
    }
  };

  const bankName = (value) => {
    switch (value) {
      case "bay":
        return "ธนาคารกรุงศรีอยุธยา";
        break;
      case "ktb":
        return "ธนาคารกรุงไทย";
        break;
      case "kbank":
        // code block
        return "ธนาคารกสิกรไทย";
        break;
      case "scb":
        // code block
        return "ธนาคารไทยพาณิชย์";
        break;
      case "bbl":
        // code block
        return "ธนาคารกรุงเทพ";
        break;
      case "ttb":
        // code block
        return "ธนาคารทีเอ็มบีธนชาต";
        break;
      case "baac":
        // code block
        return "ธนาคาร ธ.ก.ส.";
        break;
      case "gsb":
        // code block
        return "ธนาคารออมสิน";
        break;
      default:
        // code block
        return "bg-yellow-500";
    }
  };

  const gameColorHeader = (val) => {
    let backgroundColor;
    if (val == "yeekee_5") {
      backgroundColor = "bg-yellow-400";
    } else if (val == "yeekee_10") {
      backgroundColor = "bg-orange";
    } else if (val == "yeekee_15") {
      backgroundColor = "bg-green-500";
    } else if (val == "lotto_thai") {
      backgroundColor = "bg-blue";
    } else if (val == "lotto_thai_vip") {
      backgroundColor = "bg-blue";
    } else {
      backgroundColor = "bg-green-600";
    }

    return backgroundColor;
  };

  const gameColorBody = (val) => {
    let backgroundColor;

    if (val == "opened") {
      backgroundColor = "bg-green-200";
    } else if (val == "completed") {
      backgroundColor = "bg-gray-200";
    } else {
      backgroundColor = "bg-red-200";
    }

    return backgroundColor;
  };

  const customThaidate = (val) => {
    let text = val;
    const dateArray = text.split("-");
    let word = dateArray[0];
    let thaiyear = useToNumber(word);
    let result = text.replace(word, thaiyear.value + 543);
    return result;
  };

  const slug = {
    "1upper": "วิ่งบน",
    "1under": "วิ่งล่าง",
    "2upper": "สองตัวบน",
    "2under": "สองตัวล่าง",
    "2front": "สองตัวหน้า",
    "2back": "สองตัวหลัง",
    "3upper": "สามตัวบน",
    "3shuffle": "สามตัวโต๊ด",
    "3front": "สามตัวหน้า",
    "3back": "สามตัวหลัง",
    "3front_1": "สามตัวหน้า1",
    "3front_2": "สามตัวหน้า2",
    "3back_1": "สามตัวหลัง1",
    "3back_2": "สามตัวหลัง2",
    "4upper": "สี่ตัวบน",
    "4shuffle": "สี่ตัวโต๊ด",
    "6upper": "รางวัลที่ 1",
  };
  const slugName = (value) => {
    return slug[value];
  };

  const lotto_status = {
    opened: "เปิดรับแทง",
    closed: "ปิดรับแทง",
    processing: "คำนวนผลเกมส์",
    cancel: "ยกเลิกเกมส์",
    completed: "จบเกมส์",
    pending: "รอเปิดเกมส์",
  };
  const lottoStatus = (value) => {
    if (value.status == "closed" && value.results.length) {
      return "คำนวนผลเกมส์";
    }
    return lotto_status[value.status];
  };

  const isNumber = (evt) => {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    }
  };

  // const gameThaiSlug = (val) => {
  //   let text = val;
  //   const dateArray = text.split("-");
  //   let word = dateArray[0];
  //   let thaiyear = useToNumber(word);
  //   let result = text.replace(word, thaiyear.value + 543);

  //   return result;
  // };

  const lottoName = (lotto) => {
    if (lotto) {
      var textName = "";
      var round_th = useDateFormat(
        customThaidate(lotto.round_display),
        "DD MMM YYYY",
        {
          locales: "th",
        }
      ).value;

      if (lotto.round_no) {
        var nameSet =
          lotto.round_no == "AM"
            ? "เช้า"
            : lotto.round_no == "PM"
            ? "บ่าย"
            : lotto.round_no;
        textName = `${lotto.master_name} รอบ ${nameSet}`;
      } else {
        textName = `${lotto.master_name}`;
      }
      return textName + " วันที่ " + round_th;
    }
    return;
  };

  return {
    showCredit,
    showFlag,
    gameColorHeader,
    gameColorBody,
    customThaidate,
    bgBank,
    bankName,
    slugName,
    isNumber,
    lottoStatus,
    textColorBank,
    lottoName,
    // gameThaiSlug,
  };
};
